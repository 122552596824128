<template>
  <v-table
      :id="content.head"
      with-span
      class="text-size-12"
  >
    <template #thead>
      <v-table-tr>
        <v-table-th
            v-html="content.head"
            colspan="4"
            class="bg-color-primary text-color-light text-left"
        />
      </v-table-tr>
    </template>

    <template #default>
      <v-table-tr class="bg-color-nlmk-info text-bold">
        <v-table-td class="w-40">
          Вопросы для оценки
        </v-table-td>

        <v-table-td colspan="3">
          Ваша оценка
        </v-table-td>
      </v-table-tr>

      <v-table-tr>
        <v-table-td
            rowspan="6"
            class="p-0"
        >
          <div class="p51-table-cell">
            <div class="p-8">
              <ul class="ul ul_type_point">
                <li
                    v-for="(question, i) in content.questions"
                    v-html="question"
                    :key="i"
                    :class="i === 0 ? 'mt-0' : null"
                />
              </ul>
            </div>

            <div>
              <div class="bg-color-nlmk-info p-12 border-top border-bottom text-bold">
                Ответы кандидата
              </div>

              <div class="p-12">
                <v-field size="12">
                  <v-textarea
                      autogrow
                      :disabled="isSent"
                      :rows="4"
                      :value="value.comment"
                      @input="setValues({comment: $event})"
                      placeholder="Введите ответ кандидата"
                  />
                </v-field>
              </div>
            </div>
          </div>
        </v-table-td>
      </v-table-tr>

      <v-table-tr
          v-for="(rank, j) in content.ranks"
          :key="j"
          :hovered="!isSent"
          :error="content.error"
          :active="value.rank === j"
          :class="{'cursor-pointer': !isSent}"
          @click="setValues({rank: j})"
          class="user-select-none"
      >
        <v-table-td
            v-html="rank.text"
        />

        <v-table-td
            v-html="rank.rank"
            class="text-center text-size-16 text-bold"
            style="min-width: 6rem"
        />

        <v-table-td>
          <v-radio
              :model-value="value.rank"
              :value="j"
              :disabled="isSent"
              @change="setValues"
          />
        </v-table-td>
      </v-table-tr>
    </template>
  </v-table>
</template>

<script>
import {
  VField,
  VRadio,
  VTable,
  VTableTd,
  VTableTh,
  VTableTr,
  VTextarea
} from '@components/base'

export default {
  name: 'ProcessingProcess51Tab3Table',

  components: {
    VField,
    VRadio,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr,
    VTextarea
  },

  props: {
    content: Object,
    value: Object,
    isSent: Boolean
  },

  methods: {
    setValues ({
      rank = this.value.rank,
      comment = this.value.comment
    }) {
      if (this.isSent) {
        return
      }

      this.$emit('input', { rank, comment })
    }
  }
}
</script>
<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.w-40 {
  width: 40% !important;
}

.p51-table-cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
